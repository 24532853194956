/* Taustaandmed:
    Sugu (mees, naine, ei vasta)
    Valdkond (neli valdkonda);
    vanusegrupid (kuni 20, 21-25; 26-30’; ja 30+ jne),
    tase – baka, mag, dokt, integreeritud õpe, rakenduskõrgharidus
    */

import { Fragment } from "react";
import { Dialog, Transition } from '@headlessui/react';
import { useNavigate } from "react-router-dom"
import i18n from 'i18next';
import { Trans } from 'react-i18next'
import { CheckIcon, GlobeAltIcon } from "@heroicons/react/20/solid";

export default function IntroModal( {isOpen, setIsOpen} ) {

  const navigate = useNavigate();

  function closeModal() {
    setIsOpen(false);
    navigate("/");
  }

  function changeMeta() {
    document.title = i18n.t("meta_title");
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', i18n.t("meta_description"));
    }
  }

  return (
    <>
    <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" id="introModal" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="prose max-w-7xl transform overflow-hidden rounded-none bg-white p-6 text-justify align-middle shadow-xl transition-all select-none">
                  <Dialog.Title
                    as="h3"
                    className="font-bold leading-6 !text-accent uppercase focus:outline-none"
                    tabIndex={0}
                  >
                    {i18n.t("intro_modal_title")}
                    
                    <details className="dropdown dropdown-left swap">
                      <summary className="btn !rounded-full  bg-white focus:outline-none focus:ring focus:ring-yellow-400">{`${i18n.language === 'en' ? "ENG": "EST"}`} <GlobeAltIcon className="w-5 h-5 p-0 text-primary"></GlobeAltIcon></summary>
                      <ul className="menu dropdown-content bg-white z-[1] w-40 p-2 shadow text-primary">
                        <li className="justify-left flex-row content-center items-center focus:outline-none focus:ring focus:ring-yellow-400" onClick={() => { i18n.changeLanguage("et"); changeMeta(); }}>Eesti keel <CheckIcon className={`${i18n.language === 'et' ? "" : "hidden"} w-5 h-5 p-0 ml-1 cursor-none`}></CheckIcon></li>
                        <li className="justify-left flex-row content-center items-center focus:outline-none focus:ring focus:ring-yellow-400" onClick={() => { i18n.changeLanguage("en"); changeMeta(); }}>In English <CheckIcon className={`${i18n.language === 'en' ? "" : "hidden"} w-5 h-5 p-0 ml-1 cursor-none`}></CheckIcon></li>
                      </ul>
                    </details>
                  </Dialog.Title>
                  <div className="mt-2">
                  </div>

                    <div className="space-y-12">
                      <div className="border-b border-gray-900/10 pb-6 leading-6">
                        <Trans i18nKey="intro_modal_text"></Trans>
                      </div>
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">

                    </div>

                    <div className="">
                      <button
                        className="inline-flex justify-center rounded-none text-white border-transparent btn-primary px-4 py-2 font-medium uppercase hover:btn-secondary focus:outline-none focus:ring focus:ring-yellow-400"
                        onClick={closeModal} tabIndex="0"
                      >
                        {i18n.t("intro_modal_submit")}
                      </button>
                    </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}